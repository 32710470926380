import React, { useEffect, useState } from 'react'
import "./PopularMenu.css";
import { Badge, Button, Col, Modal, ModalBody, Popover, PopoverBody, Row, UncontrolledPopover } from 'reactstrap'
import 'swiper/css';
import 'swiper/css/navigation';
import CommonService from 'Services/CommonService';
import { IRestaurantCreationModel } from 'Models/IRestaurantCreationModel';
import { HelperConstant, dateByDayId, days, formatDay, formatTimeString } from 'Components/constants/PandaConstants';
import { useNavigate } from 'react-router-dom';
import { ITokenModel } from 'Models/ITokenModel';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { EncodeParam, getTokenDetail } from '../../helpers/helper';
import { Loader } from 'Components/Loader/Loader';


const PopularMenu = () => {
    const [restaurant, setRestaurant] = useState<Array<IRestaurantCreationModel>>([]);
    const [isLogin, setIsLogin] = useState(false);
    const [cutOffTime, setCutOffTime] = useState("");
    const navigate = useNavigate();
    const [popoverOpen, setPopoverOpen] = useState(null);
    const [isDeliveryDate, setIsDeliveryDate] = useState("");
    const [isTodayMenu, setIsTodayMenu] = useState(true);
    const [isTomorrowMenu, setIsTomorrowMenu] = useState(true);
    const [day, setDay] = useState<any>("");
    const [loader, setLoader] = useState(true);
    const [isMenu, setIsmenu] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState("");
    let token: string = localStorage.getItem("authUser") as string;
    const [clickedImageIndex, setClickedImageIndex] = useState(0);
    const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);
    let tokendata: ITokenModel = {
        name: '',
        Password: '',
        Email: '',
        LoginId: '',
        StatusId: 0,
        token: '',
        RoleId: '',
        UniversityId: undefined
    }
    if (token) {
        tokendata = jwtDecode(token);
    }

    document.title = "Menu | Panda GoGoGo ";

    const getAllRestaurant = () => {
        let day = moment().utc().format('dddd');
        let dayId = days.find(item => item.day == day)?.id;
        let todaydate = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        if (tokendata.LoginId) {
            CommonService.postWithQueryParam("Menu", "GetMenuDetailsByUniversityId", "universityId", tokendata.UniversityId).then(res => {
                if (res.status == 200) {
                    let data = res.data.responseData as any;
                    let time = moment(data[0]?.cutOffTime, 'HH:mm:ss').format('hh:mm A');
                    let dayId = data[0]?.dayId;
                    setDay(days.find(item => item.id == dayId)?.day);
                    let day = moment().format('dddd');
                    let todayId = days.find(item => item.day == day)?.id;
                    let difference;
                    let tomorrowId;
                    if (todayId !== undefined && dayId < todayId) {
                        difference = (7 - todayId) + dayId;
                    }
                    else if (todayId !== undefined && dayId > todayId) {
                        difference = dayId - todayId;
                        tomorrowId = todayId + 1;
                    }
                    // let tomorrowId = days.find(item => item.day == dayId+1)?.id;
                    setIsTomorrowMenu(dayId === tomorrowId ? true : false);
                    setIsTodayMenu(dayId === todayId ? true : false)
                    let cutOffDate;
                    if (dayId != undefined) {
                        cutOffDate = dayId === todayId ? moment(new Date().toISOString()).format('DD MMMM YYYY') : moment(new Date(new Date().setDate(new Date().getDate() + Math.abs(difference))).toISOString()).format('DD MMMM YYYY,');
                    }
                    if (dayId === todayId) {
                        cutOffDate = moment().format('DD MMMM YYYY');
                        setIsDeliveryDate(moment(new Date().toISOString()).format('YYYY-MM-DD'));
                    }
                    else {
                        setIsDeliveryDate(moment(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()).format('YYYY-MM-DD'))
                    }
                    setCutOffTime(cutOffDate + " " + time);
                    if (data[0] == null) {
                        setRestaurant([]);
                        setIsmenu(false);
                    }
                    else {
                        setIsmenu(true)
                        setRestaurant(data);
                    }
                    setLoader(false);
                }
            });
        }
        else {
            CommonService.get("Menu", "GetAllMenuBeforeLogin").then((res) => {
                if (res.status == 200) {
                    let data = res.data.responseData as any;
                    data.map((order, index) => {
                        order.deliveryTime = formatTimeString(order.deliveryCutOffStartTime, order.deliveryCutOffEndTime);
                    })
                    let time = moment(data[0]?.cutOffTime, 'HH:mm:ss').format('hh:mm A');
                    let dayId = data[0]?.dayId;
                    setDay(days.find(item => item.id == dayId)?.day);
                    let day = moment().format('dddd');
                    let todayId = days.find(item => item.day == day)?.id;
                    let difference;
                    let tomorrowId;
                    if (todayId !== undefined && dayId < todayId) {
                        difference = (7 - todayId) + dayId;
                    }
                    else if (todayId !== undefined && dayId > todayId) {
                        difference = dayId - todayId;
                        tomorrowId = todayId + 1;
                    }
                    setIsTomorrowMenu(dayId === tomorrowId ? true : false);
                    setIsTodayMenu(dayId === todayId ? true : false)
                    let cutOffDate;
                    if (dayId != undefined) {
                        cutOffDate = dayId === todayId ? moment(new Date().toISOString()).format('DD MMMM YYYY') : moment(new Date(new Date().setDate(new Date().getDate() + Math.abs(difference))).toISOString()).format('DD MMMM YYYY,');
                    }
                    if (dayId === todayId) {
                        cutOffDate = moment().format('DD MMMM YYYY');
                        setIsDeliveryDate(moment(new Date().toISOString()).format('YYYY-MM-DD'));
                    }
                    else {
                        setIsDeliveryDate(moment(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()).format('YYYY-MM-DD'))
                    }
                    setCutOffTime(cutOffDate + " " + time);
                    setDeliveryDate(cutOffDate);
                    if (data[0] == null) {
                        setRestaurant([]);
                        setIsmenu(false);
                    }
                    else {
                        setIsmenu(true)
                        setRestaurant(data);
                    }
                    setLoader(false);
                }
            })
        }
    }

    useEffect(() => {
        getAllRestaurant();
    }, []);

    const onStartOrderClick = (id: any, restaurentId: any, dayId: any) => {
        let encodedId = EncodeParam(id);
        let encodedRestaurentId = EncodeParam(restaurentId);
        let encodedDayId = EncodeParam(dayId);
        navigate(`/pickup-address/${encodedRestaurentId}/${encodedId}/${encodedDayId}`);
        // setIsModalOpen(true);
    }

    const onLoginClick = () => {
        navigate('/login');
    }

    const toggleImagePopup = () => {
        setClickedImageIndex(0);
        setIsImagePopupOpen(!isImagePopupOpen);
    }

    const handleImagePopup = (index: any) => {
        setClickedImageIndex(index);
        setIsImagePopupOpen(true);
    }

    const handleImageClose = () => {
        setClickedImageIndex(0);
        setIsImagePopupOpen(false);
    }

    useEffect(() => {
        let token = getTokenDetail();
        if (token == null) {
            setIsLogin(true);
        }
    }, []);

    const togglePopover = (index: any) => {
        setPopoverOpen(popoverOpen === index ? null : index);
    };

    return (
        <div className="bg-white p-3 p-lg-5 container-fluid default-height" id='menu-item' >
            <Loader open={loader} />
            <Row>
                <Col className='justify-content-end text-end'>
                    {(!isLogin && cutOffTime && restaurant?.length > 0) && <><span
                        className="text-light cutoff-badge"
                        style={{
                            backgroundColor: '#E75605',
                            // padding: "10px",
                            fontSize: "12px",
                            marginRight: "10px",
                            marginBottom: "15px",
                            padding: "5px",
                            borderRadius: "5px",
                            fontWeight: 600
                        }}
                    >
                        Order CutOff Time : {cutOffTime}
                    </span>
                        {/* <span
                            className="text-light cutoff-badge"
                            style={{
                                backgroundColor: '#E75605',
                                // padding: "10px",
                                fontSize: "12px",
                                marginRight: "10px",
                                marginBottom: "15px",
                                padding: "5px",
                                borderRadius: "5px",
                                fontWeight: 600
                            }}
                        >
                            Pickup Date: {isDeliveryDate}
                        </span> */}
                    </>
                    }
                </Col>
            </Row>
            <Row className='pm-heading'>
                <Col className='text-center'>
                    <h5 style={{ display: 'inline-block', marginRight: '10px' }}>{isMenu ? (!isLogin ? `${day}'s Menu` : 'Menu') : "Menu"}</h5>
                </Col>
            </Row>
            <Row>
                {(restaurant[0]?.conditionText === HelperConstant.cutOffTimeClosedConditionText) &&
                    <Col lg={12}>
                        <p className='m-3 twopm-text'>{restaurant[0]?.closedMessage}</p>
                    </Col>
                }
            </Row>
            <Row>
                {(restaurant[0]?.conditionText != HelperConstant.cutOffTimeClosedConditionText) && restaurant[0]?.conditionText !== HelperConstant.allMenuConditionText && restaurant[0]?.closedMessage !== null && restaurant?.length != 0 &&
                    <><Col lg={3}></Col>
                        <Col lg={6}>
                            <div className='card-closed'>
                                <Row>
                                    <div className="col-8">
                                        <p className='closed-text'>{restaurant[0]?.closedMessage}</p>
                                    </div>
                                    <div className="text-end col-4">
                                        <img src={require("../../assets/images/logo.png")} alt={""} className='closed-img' />
                                    </div>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={3}></Col>
                    </>
                }
            </Row>
            {restaurant?.length == 0 &&
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <div className='card-closed'>
                            <Row>
                                <div className="col-8">
                                    <p className='closed-text'>There is no Menu for Today, Please visit Again Tomorrow..</p>
                                </div>
                                <div className="text-end col-4">
                                    <img src={require("../../assets/images/logo.png")} alt={""} className='closed-img' />
                                </div>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={3}></Col>
                </Row>
            }
            {isLogin ?
                <Row>
                    {restaurant.map((val, i) => (
                        <Col xl={4} lg={6} md={12} sm={12} xs={12} className='mr-5' key={i}>
                            <div className="pm-card-container">
                                <div key={i} className="u-clearfix pm-card">
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={12} >
                                            <p className="pm-card-title mb-0">{val?.menuName} - <span className='primary-color bold'>${val?.amount}</span></p>
                                            <p className='pm-card-universityname mb-0'>{val?.universityName}</p>
                                            <p className='pm-card-names mt-1 mb-0'>Pickup Date: <span className='primary-color bold'>{dateByDayId(val?.dayId)}</span></p>
                                            <p className='pm-card-names mt-1 mb-0'>Pickup Time: <span className='primary-color bold'>{val.deliveryTime}</span></p>
                                            <p className="pm-card-names mt-1 mb-0">Pickup Location : <span className='pm-card-location'>{val?.pickupAddressName}</span></p>
                                        </Col>
                                        {/* <Col lg={8}> */}
                                        <Col xs={4}>
                                            <Badge color={"success"} className='day-badge' style={{ fontSize: '14px', marginLeft: '15px' }}>{`${formatDay(val?.dayId)}`}</Badge>
                                            {val.id > 0 ? <img src={`${HelperConstant.image_cdn_Url}/${val.menuUploadFilePath}`} alt={val.name} className="pm-card-media" onClick={() => handleImagePopup(i)} id={`PopoverLegacy-${i}`}
                                                onMouseEnter={() => togglePopover(i)}
                                                onMouseLeave={() => togglePopover(null)} />
                                                :
                                                <img src={require("../../assets/images/logo.png")} alt={val.name} className="pm-card-media" />
                                            }
                                        </Col>
                                        <Modal isOpen={isImagePopupOpen} toggle={toggleImagePopup} centered size='md'>
                                            {/* <ModalHeader toggle={togglePaymentType}>Select Mode of Payment</ModalHeader> */}
                                            <ModalBody>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <img src={`${HelperConstant.image_cdn_Url}/${restaurant[clickedImageIndex]?.menuUploadFilePath}`} alt={val.name} className="menuimg" onClick={handleImageClose} id={`PopoverLegacy-${i}`} />
                                                </Col>
                                            </ModalBody>
                                        </Modal>
                                        {/* </Col> */}
                                    </Row>
                                    {val?.description ?
                                        <Popover
                                            placement="top-start"
                                            target={`PopoverLegacy-${i}`}
                                            trigger="PopoverLegacy"
                                            isOpen={popoverOpen === i}
                                            toggle={() => togglePopover(i)}
                                        >
                                            <p
                                                className='popover-tip'
                                            >
                                                {val?.description}
                                            </p>
                                        </Popover> : ""}
                                    <div className="pm-card-body">
                                        <Row>

                                            <Col xs={8} className='mr-5'>
                                                {/* <div className="pm-card-title-container">
                                                    <p className="pm-card-description mb-0"> {val?.description}</p>
                                                </div> */}
                                                <div className="button-container">
                                                    <Button
                                                        style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                                        type={"submit"} onClick={() => tokendata.Email ? onStartOrderClick(val.id, val.restaurentId, val.dayId) : onLoginClick()} disabled={val?.closedMessage == HelperConstant.MenuCutOffTimeText}>Login to Order
                                                    </Button>

                                                </div>
                                            </Col>
                                            {/* <Col xs={4}>
                                                {val.id > 0 ? <img src={`${HelperConstant.image_cdn_Url}/${val.menuUploadFilePath}`} alt={val.name} className="pm-card-media" id={`PopoverLegacy-${i}`} onClick={() => togglePopover(i)} />
                                                    :
                                                    <img src={require("../../assets/images/logo.png")} alt={val.name} className="pm-card-media" />
                                                }
                                            </Col> */}
                                        </Row>

                                    </div>
                                    {/* <div className="button-container">
                                        <Button
                                            style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                            type={"submit"} onClick={() => tokendata.Email ? onStartOrderClick(val.id, val.restaurentId) : onLoginClick()}>Order Now
                                        </Button>
                                    </div> */}
                                </div>
                                <div className="card-shadow"></div>
                            </div>
                        </Col>
                    ))}
                    {/* <Col lg={3}></Col>
                    <Col lg={6}>
                        <div className='card-closed'>
                            <Row>
                                <Col xs={6}>
                                    <p className='pm-card-title mb-3'>Menu</p>
                                    <Button
                                        style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                        type={"submit"} onClick={() => onLoginClick()}>Login to Order
                                    </Button>
                                </Col>
                                <Col xs={6} className='text-center'>
                                    <img src={require("../../assets/images/logo.png")} className="pm-card-media" style={{ height: "100%" }} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={3}></Col> */}
                </Row> :
                <Row>
                    {restaurant.map((val, i) => (
                        <><Col xl={4} lg={6} md={12} sm={12} xs={12} className='mr-5' key={i}>
                            <div className="pm-card-container">
                                <div key={i} className="u-clearfix pm-card">
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={12}>
                                            <p className="pm-card-title mb-0">{val?.menuName} - <span className='primary-color bold'>${val?.amount}</span></p>
                                        </Col>
                                        {/* <Col lg={8}> */}
                                        <Col xs={4}>
                                            {val.id > 0 ? <img src={`${HelperConstant.image_cdn_Url}/${val.menuUploadFilePath}`} alt={val.name} className="pm-card-media" onClick={() => handleImagePopup(i)} id={`PopoverLegacy-${i}`}
                                                onMouseEnter={() => togglePopover(i)}
                                                onMouseLeave={() => togglePopover(null)} />
                                                :
                                                <img src={require("../../assets/images/logo.png")} alt={val.name} className="pm-card-media" />}
                                        </Col>
                                        <Modal isOpen={isImagePopupOpen} toggle={toggleImagePopup} centered size='md'>
                                            {/* <ModalHeader toggle={togglePaymentType}>Select Mode of Payment</ModalHeader> */}
                                            <ModalBody>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <img src={`${HelperConstant.image_cdn_Url}/${restaurant[clickedImageIndex]?.menuUploadFilePath}`} alt={val.name} className="menuimg" onClick={handleImageClose} id={`PopoverLegacy-${i}`} />
                                                </Col>
                                            </ModalBody>
                                        </Modal>
                                        {/* </Col> */}
                                    </Row>
                                    {val?.description ?
                                        <Popover
                                            placement="top-start"
                                            target={`PopoverLegacy-${i}`}
                                            trigger="PopoverLegacy"
                                            isOpen={popoverOpen === i}
                                            toggle={() => togglePopover(i)}
                                        >
                                            <p
                                                className='popover-tip'
                                            >
                                                {val?.description}
                                            </p>
                                        </Popover> : ""}
                                    <div className="pm-card-body">
                                        <Row>
                                            <Col xs={8} className='mr-5'>
                                                {/* <div className="pm-card-title-container">
        <p className="pm-card-description mb-0"> {val?.description}</p>
    </div> */}

                                                <div className="button-container">
                                                    {val?.isCountReached == true ? <Button
                                                        style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                                        type={"button"} disabled={true}>Menu Closed
                                                    </Button> : <Button
                                                        style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                                        type={"submit"} onClick={() => tokendata.Email ? onStartOrderClick(val.id, val.restaurentId, val.dayId) : onLoginClick()} disabled={val?.conditionText === HelperConstant.cutOffTimeClosedConditionText}>Order Now
                                                    </Button>}

                                                </div>
                                            </Col>
                                            {/* <Col xs={4}>
        {val.id > 0 ? <img src={`${HelperConstant.image_cdn_Url}/${val.menuUploadFilePath}`} alt={val.name} className="pm-card-media" id={`PopoverLegacy-${i}`} onClick={() => togglePopover(i)} />
            :
            <img src={require("../../assets/images/logo.png")} alt={val.name} className="pm-card-media" />
        }
    </Col> */}
                                        </Row>

                                    </div>
                                    {/* <div className="button-container">
        <Button
            style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
            type={"submit"} onClick={() => tokendata.Email ? onStartOrderClick(val.id, val.restaurentId) : onLoginClick()}>Order Now
        </Button>
    </div> */}
                                </div>
                                <div className="card-shadow"></div>
                            </div>
                        </Col></>
                    ))}

                </Row>
            }
        </div >
    )
}

export default PopularMenu