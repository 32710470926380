import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container, Form, Modal, Alert, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { apiError } from "../../slices/thunk";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import profile from "../../assets/images/login/signupprofile1.png";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import { HelperConstant, Role, Toaster_ENUM } from "Components/constants/PandaConstants";
import { CustomEmailBox } from "Components/Shared/CustomEmailBox";
import { CustomPhoneNumberBox } from "Components/Shared/CustomPhoneNumberBox";
import { CustomPasswordBox } from "Components/Shared/CustomPasswordBox";
import { CustomCheckBox } from "Components/Shared/CustomCheckBox";
import { CustomToaster } from "Components/Shared/CustomToaster";
import CommonService from "Services/CommonService";
import { IUserCreationModel } from "Models/IUserCreationModel";
import { forkJoin } from "rxjs";
import { CustomAutoComplete } from "Components/Shared/CustomAutoComplete";
import TermsAndConditionPopUp from "./Terms-Condition";
import { Loader } from "Components/Loader/Loader";
import PrivacyPolicy from "./Privacy-Policy";
import { EncodeParam } from "../../helpers/helper";
import moment from "moment";

const Register = props => {
  const dispatch = useDispatch<any>();
  const [termsChecked, setTermsChecked] = useState(false);
  const [toast, SetToast] = useState(false);
  const [countryMaster, setCountryMaster] = useState<any>([]);
  const [universityMaster, setUniversityMaster] = useState<any>([]);
  const [existingEmail, setExistingEmail] = useState("");
  const [existingMobile, setExistingMobile] = useState("");
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [privacy_popup, setPrivacyPopup] = useState(false);

  const [message, setMessage] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [checkErr, setCheckError] = useState("");

  document.title = "Register | Panda GoGoGo ";

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      password: '',
      confirmpassword: '',
      universityId: '',
      // checkbox: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please Enter Your First Name")
        .max(50, "First Name must be at most 50 characters")
        .matches(/^[^\d]+$/, 'First Name must not contain numbers'),
      lastName: Yup.string().required("Please Enter Your Last Name")
        .max(50, "Last Name must be at most 50 characters")
        .matches(/^[^\d]+$/, 'Last Name must not contain numbers'),
      email: Yup.string().required("Please Enter Your Email").matches(HelperConstant.emailPattern.pattern, 'Please Enter a Valid Email'),
      mobileNumber: Yup.string().required('Please Enter Your Mobile Number').matches(HelperConstant.phonenumberPattern.pattern, "Please Enter valid Mobile Number"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmpassword: Yup.string().required("Please Enter Your Confirm Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
      universityId: Yup.string().required("Please Select Your University"),
      // checkbox: Yup.string(),
    }),
    onSubmit: (values: any) => {
      if (!termsChecked) {
        setCheckError("Please check the box to agree to the terms and conditions.");
      }
      else {
        validateEmail(values);
      }
      // createUser(values);
    }
  });


  const createUser = (data: IUserCreationModel) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("id", 0 as any);
    formData.append("firstName", validation.values.firstName);
    formData.append("lastName", validation.values.lastName);
    formData.append("email", validation.values.email);
    formData.append("mobileNumber", validation.values.mobileNumber);
    formData.append("uploadedFile", null as any);
    formData.append("uploadfilepath", null as any);
    formData.append("profileImage", null as any);
    formData.append("universityId", validation.values.universityId as any);
    formData.append("userLogin.id", 0 as any);
    formData.append("userLogin.userName", validation.values.email);
    formData.append("userLogin.password", EncodeParam(validation.values.password));
    formData.append("userLogin.userRoleId", Role.User as any);
    formData.append("userAddress", null as any);
    // formData.append("userAddress.loginId", 0 as any);
    // formData.append("userAddress.name", validation.values.firstName + validation.values.lastName);
    // formData.append("userAddress.address", "");
    // formData.append("userAddress.countryId", validation.values.countryId as any);
    // formData.append("userAddress.stateId", validation.values.stateId as any);
    // formData.append("userAddress.cityId", validation.values.cityId as any);
    // formData.append("userAddress.zipId", validation.values.zipId as any);
    // formData.append("userAddress.isPrimaryAddress", true as any);

    CommonService.postWithFormData("User", "CreateUser", formData).then(res => {
      if (res.status === 200) {
        setLoader(false);
        setMessage("Shortly, You Will Receive the Activitation link in Your Mail, Kindly Check the Spam if You are not receiving the Mail in Inbox !!");
        setTimeout(() => {
          window.location.href = "/";
        }, 8000);
      }
    })
  }

  const validateEmail = (values: any) => {
    CommonService.getWithSingleParam("User", "RegisteredUserName", values.email).then(res => {
      if (res.status === 200) {
        if (res.data == "You are already Registered Email.") {
          return setExistingEmail(res.data);
        }
        else {
          setExistingEmail("");
          if( values.mobileNumber !== ""){
            validatePhoneNumber(values);
          }else{
            createUser(values);
          }
        }
      }
    })
  }

  const validatePhoneNumber = (values: any) => {
    CommonService.getWithSingleParam("User", "RegisteredMobileNo", values.mobileNumber).then(res => {
      if (res.status === 200) {
        if (res.data == "You are already Registered MobileNo.") {
          return setExistingMobile(res.data);
        }
        else {
          setExistingMobile("");
          createUser(values);
        }
      }
    })
  }

  const openPopUp = () => {
    setmodal_scroll(true);
  }

  const isCancel = () => {
    setmodal_scroll(false);
  }

  const privacyPopup = () => {
    setPrivacyPopup(true);
  }

  const isCancelPrivacy = () => {
    setPrivacyPopup(false);
  }

  const automaticUpdateCouponExpiry = () => {
    CommonService.putWithQueryParam("CouponCode", "UpdateCouponCodeExpired", "toDate", moment.utc().format("YYYY-MM-DD HH:mm:ss")).then(res => {
      if (res.status === 200) {
      }
    })
  }

  useEffect(() => {
    dispatch(apiError());
  }, [dispatch]);


  useEffect(() => {
    automaticUpdateCouponExpiry();
    let country = CommonService.get("Masters", "GetCountryMaster");
    let university = CommonService.get("University", "GetAllUniversityMaster");
    // let state = CommonService.get("Masters", "GetStateMaster");
    // let city = CommonService.get("Masters", "GetCityMaster");
    // let zipcode = CommonService.get("Masters", "GetZipCodeMaster");

    forkJoin({ country, university }).subscribe((res: { country: any, university: any }) => {
      setCountryMaster(res.country.data.responseData.countryList);
      setUniversityMaster(res.university.data.responseData);
      // setStateMaster(res.state.data.responseData.stateList);
      // setCityMaster(res.city.data.responseData.cityList);
      // setZipCodeMaster(res.zipcode.data.responseData.zipCodeList);
    })
  }, []);

  return (
    <React.Fragment>
      <div className="pt-sm-5 account-pages default-height register-app">
        <Loader open={loader} />
        <Container>
          <Row>
            <Col md={2} lg={3} xl={3}></Col>
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                <div className="background-grid">
                  <Row className="justify-content-center">
                    <Col className="col-7">
                      <div className="p-4 text-primary">
                        <h3 className="header-text-grid">Welcome !</h3>
                        <h4 className="header-text-grid" style={{ lineHeight: "30px" }}>Register to</h4>
                        <h4 className="header-text-grid" style={{ lineHeight: "30px", whiteSpace: "nowrap" }}>Panda GoGoGo.</h4>
                      </div>
                    </Col>
                    <Col className="col-5">
                      <img src={profile} alt="" className="profile-login" style={{ height: "150px" }}  />
                    </Col>
                  </Row>
                </div>
                <CardBody className="mx-2 mt-2 pt-0">
                  <div className="p-1">
                    <Form onSubmit={validation.handleSubmit}>
                      {message &&
                        <div className="row">
                          <div className="text-center col-12">
                            <Alert
                              color="success"
                              className="fade show"
                              role="alert"
                            >
                              <b>{message}</b>
                            </Alert>
                          </div>
                        </div>
                      }
                      <Row>
                        <Col lg={6}>
                          <CustomTextBox id="firstName"
                            placeholder="Enter First Name" name="firstName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName}
                            invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                            label="First Name" error={validation.errors.firstName} isMandatory={true} />
                        </Col>
                        <Col lg={6}>
                          <CustomTextBox id="lastName"
                            placeholder="Enter Last Name" name="lastName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName}
                            invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                            label="Last Name" error={validation.errors.lastName} isMandatory={true} />
                        </Col>
                        <Col lg={6}>
                          <CustomEmailBox label="Email" name="email" id="email" placeholder="Enter Email" value={validation.values.email}
                            onChange={(e) => { validation.handleChange(e); setExistingEmail("") }} onBlur={validation.handleBlur} invalid={validation.errors.email && validation.touched.email ? true : false}
                            error={validation.errors.email} isMandatory={true} emailvalidationError={existingEmail} />

                        </Col>
                        <Col lg={6}>
                          <CustomPhoneNumberBox label="Mobile Number" id="mobileNumber" placeholder="914-999-6826" name="mobileNumber"
                            value={validation.values.mobileNumber} error={validation.errors.mobileNumber} 
                            onChange={(e) => { validation.handleChange(e); setExistingMobile("") }} onBlur={validation.handleBlur} touched={validation.touched.mobileNumber}
                            invalid={validation.errors.mobileNumber && validation.touched.mobileNumber ? true : false} isMandatory={true} phoneValidationError={existingMobile} />
                        </Col>
                        <Col lg={6}>
                          <CustomPasswordBox
                            label="Password"
                            placeholder="Enter Password"
                            id="password"
                            value={validation.values.password}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            error={validation.errors.password}
                            touched={validation.touched.password}
                            invalid={validation.errors.password && validation.touched.password ? true : false}
                            isMandatory={true}
                          />
                        </Col>
                        <Col lg={6}>
                          <CustomPasswordBox
                            name="confirmpassword"
                            label="Confirm Password"
                            placeholder="Enter Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmpassword}
                            touched={validation.touched.confirmpassword}
                            invalid={validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false}
                            error={validation.errors.confirmpassword}
                            isMandatory={true} />
                        </Col>
                        <Col lg={12}>
                          <CustomAutoComplete
                            label="University" id="universityId"
                            data={universityMaster}
                            onChange={(e, v) => { validation.setFieldValue("universityId", v.id) }}
                            placeholder="Universtiy"
                            name="universityId"
                            isMandatory={true}
                            error={validation.errors.universityId}
                            touched={validation.touched.universityId}
                            displayField="displayName"
                            valueField="id"
                            defaultValue={validation.values.universityId} />
                        </Col>
                        {/* <Col lg={6}>
                          <CustomDropdown label="Country" id="countryId" value={validation.values.countryId} data={countryMaster}
                          error={validation.errors.countryId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                          onBlur={validation.handleBlur} invalid={validation.touched.countryId && validation.errors.countryId ? true : false}
                          touched={validation.touched.countryId} isMandatory={true} />
                          <CustomAutoComplete
                            label="Country" id="countryId"
                            data={countryMaster}
                            onChange={(e, v) => { validation.setFieldValue("countryId", v.id); getStateByCountryId(v.id) }}
                            placeholder="Country"
                            name="countryId"
                            isMandatory={true}
                            error={validation.errors.countryId}
                            touched={validation.touched.countryId}
                            displayField="displayName"
                            valueField="id"
                            defaultValue={validation.values.countryId} />
                        </Col>

                        <Col lg={6}>
                          <CustomDropdown label="State" id="stateId" value={validation.values.stateId} data={stateMaster}
                          error={validation.errors.stateId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                          onBlur={validation.handleBlur} invalid={validation.touched.stateId && validation.errors.stateId ? true : false}
                          touched={validation.touched.stateId} isMandatory={true} />
                          <CustomAutoComplete
                            label="State" id="stateId"
                            data={stateMaster}
                            onChange={(e, v) => { validation.setFieldValue("stateId", v.id); getCityByStateId(v.id) }}
                            placeholder="State"
                            name="stateId"
                            isMandatory={true}
                            error={validation.errors.stateId}
                            touched={validation.touched.stateId}
                            displayField="displayName"
                            valueField="id"
                            defaultValue={validation.values.stateId} />
                        </Col>

                        <Col lg={6}>
                          <CustomDropdown label="City" id="cityId" value={validation.values.cityId} data={cityMaster}
                          error={validation.errors.cityId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                          onBlur={validation.handleBlur} invalid={validation.touched.cityId && validation.errors.cityId ? true : false}
                          touched={validation.touched.cityId} isMandatory={true} />
                          <CustomAutoComplete
                            label="City" id="cityId"
                            data={cityMaster}
                            onChange={(e, v) => { validation.setFieldValue("cityId", v.id); getZipCodeByCityId(v.id) }}
                            placeholder="City"
                            name="cityId"
                            isMandatory={true}
                            error={validation.errors.cityId}
                            touched={validation.touched.cityId}
                            displayField="displayName"
                            valueField="id"
                            defaultValue={validation.values.cityId} />
                        </Col>

                        <Col lg={6}>
                          <CustomDropdown label="Zip Code" id="zipId" value={validation.values.zipId} data={zipCodeMaster}
                          error={validation.errors.zipId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                          onBlur={validation.handleBlur} invalid={validation.touched.zipId && validation.errors.zipId ? true : false}
                          touched={validation.touched.zipId} isMandatory={true} />
                          <CustomAutoComplete
                            label="ZipCode" id="zipId"
                            data={zipCodeMaster}
                            onChange={(e, v) => { validation.setFieldValue("zipId", v.id); }}
                            placeholder="ZipCode"
                            name="zipId"
                            isMandatory={true}
                            error={validation.errors.zipId}
                            touched={validation.touched.zipId}
                            displayField="displayName"
                            valueField="id"
                            defaultValue={validation.values.zipId} />
                        </Col> */}
                      </Row>
                      <Row className="mt-3">
                        <Col lg={8} md={8}>
                          <div className="registration-custom-checkbox nowrap">
                            <CustomCheckBox
                              checked={termsChecked}
                              id="checkbox"
                              value={termsChecked}
                              error={checkErr}
                              onChange={() => {
                                setTermsChecked(!termsChecked);
                                if (termsChecked) {
                                  setCheckError("Please check the box to agree to the terms and conditions.");
                                }
                                else {
                                  setCheckError("");
                                }
                              }}
                              defaultChecked={false}
                              touched={""}
                              label={<div> <p className="mb-0"> <b>I agree to the <Link to="#" className="fw-medium text-primary" onClick={openPopUp}>Terms & Conditions </Link></b></p> </div>} />
                          </div></Col>
                        <Col lg={4} md={4} className="text-end">
                          <Link to="#" className="text-muted" onClick={privacyPopup}><i className="mdi mdi-lock me-1" /> Privacy Policy</Link>
                        </Col>
                      </Row>
                      {/* <div className="flex">
                        <div className="registration-custom-checkbox mt-4">
                          <CustomCheckBox
                            checked={termsChecked}
                            id="checkbox"
                            value={termsChecked}
                            error={checkErr}
                            onChange={() => {
                              setTermsChecked(!termsChecked);
                              if (termsChecked) {
                                setCheckError("Please check the box to agree to the terms and conditions.");
                              }
                              else {
                                setCheckError("");
                              }
                            }}
                            defaultChecked={false}
                            touched={""}
                            label={<div> <p className="mb-0"> <b>I agree to the <Link to="#" className="fw-medium text-primary" onClick={openPopUp}>Terms & Conditions </Link></b></p> </div>} />
                        </div>
                        <Link to="/privacy-policy" className="text-muted forgot_pass_style"><i className="mdi mdi-lock me-1" /> Privacy Policy</Link>
                      </div> */}
                      <div className="d-grid mt-4">
                        <button className="loginbtn" type="submit"> Register</button>
                      </div>
                    </Form>
                  </div>
                  <div className="mt-3 text-center">
                    <p> Already have an account ?{" "} <Link to="/login" className="fw-medium text-primary">{" "}Login Here </Link>{" "}</p>
                    <p> Pandagogogo © {new Date().getFullYear()}. All Rights Reserved..</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={2} lg={3} xl={3}></Col>
          </Row>
        </Container>
      </div>
      <div className="container">
        <Modal isOpen={modal_scroll} size="lg" centered>
          <div role='document'>
            <div className='modal-content'>
              {/* <p onClick={() => {
                isCancel()
              }} style={{ textAlign: "right" }}><i className="fa-times fas" style={{ fontSize: "20px" }}></i></p> */}
              <div className='m-1 modal-body'>
                <TermsAndConditionPopUp
                  isOpen={true}
                  toggle={isCancel}
                  checked={termsChecked}
                  setTermsChecked={setTermsChecked}
                  setCheckError={setCheckError}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="container">
        <Modal isOpen={privacy_popup} size="lg" centered>
          <div role='document'>
            <div className='modal-content'>
              <div className='m-1 modal-body'>
                <PrivacyPolicy
                  isOpen={true}
                  toggle={isCancelPrivacy}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <CustomToaster message={Toaster_ENUM.create.text} color={"text-success"} toast={toast} setToast={SetToast} path="/login" />
    </React.Fragment>
  );
};

export default Register;
